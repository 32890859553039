var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    { attrs: { id: "sidebar" } },
    [
      _c("div", { attrs: { id: "coef-buttons" } }, [
        _c(
          "button",
          { staticClass: "md-raised md-primary", on: { click: _vm.push } },
          [_vm._v("ADD COEF")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "md-raised md-secondary",
            on: { click: _vm.pop_coef }
          },
          [_vm._v("DELETE COEF")]
        ),
        _vm._v(" "),
        _c("button", { on: { click: _vm.hurwitz } }, [_vm._v("EXECUTE")])
      ]),
      _vm._v(" "),
      _c("div", { attrs: { id: "execution" } }),
      _vm._v(" "),
      _vm._l(_vm.coefficients, function(coef) {
        return _c("coefficient-input", {
          key: coef.id,
          tag: "div",
          attrs: {
            label: coef.name,
            coefficient: coef.value,
            index: coef.index
          },
          on: { input: _vm.updatedInput }
        })
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }