var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("link", {
      attrs: {
        rel: "stylesheet",
        href:
          "//fonts.googleapis.com/css?family=Roboto:400,500,700,400italic|Material+Icons"
      }
    }),
    _vm._v(" "),
    _c(
      "div",
      { attrs: { id: "content" } },
      [
        _c("display", {
          attrs: {
            coefficients: _vm.coefficients,
            isHurwitzStable: _vm.isHurwitzStable
          }
        }),
        _vm._v(" "),
        _c(
          "div",
          { attrs: { id: "chart-sidebar" } },
          [
            _c("chart", { attrs: { chartData: _vm.chartData } }),
            _vm._v(" "),
            _c("sidebar", {
              on: {
                changeChartData: _vm.changeChartData,
                changeCoefficient: _vm.changeCoefficient
              }
            })
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }