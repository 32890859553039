var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "display" } }, [
    _vm.isHurwitzStable !== null
      ? _c("div", [
          _vm.isHurwitzStable
            ? _c("h1", { style: { color: "blue" } }, [
                _vm._v("This polynomial is Hurwitz Stable")
              ])
            : _c("h1", { style: { color: "red" } }, [
                _vm._v("This polynomial is not Hurwitz Stable")
              ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.formula !== null
      ? _c(
          "div",
          [_c("vue-mathjax", { attrs: { formula: _vm.formula, safe: false } })],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }