var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    { staticClass: "coefficient-input" },
    [
      _c(
        "md-field",
        [
          _c("label", [_vm._v(_vm._s(_vm.label))]),
          _vm._v(" "),
          _c("md-input", {
            attrs: { value: _vm.coefficient, type: "number" },
            on: {
              input: function($event) {
                _vm.$emit("input", _vm.index, _vm.value)
              }
            },
            model: {
              value: _vm.value,
              callback: function($$v) {
                _vm.value = $$v
              },
              expression: "value"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }